import React from 'react';
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import DataObjectIcon from '@mui/icons-material/DataObject';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';

import { blue, green, orange, red, teal } from '@mui/material/colors';

export const mimeToReadableName: Record<string, { name: string, code: string }> = {
  'text/x-c': { name: 'C', code: 'code' },
  'text/x-csharp': { name: 'C#', code: 'code' },
  'text/x-c++': { name: 'C++', code: 'code' },
  'application/msword': { name: 'Documento Word', code: 'document' },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { name: 'Documento Word', code: 'document' },
  'text/html': { name: 'HTML', code: 'code' },
  'text/x-java': { name: 'Java', code: 'code' },
  'application/json': { name: 'JSON', code: 'structuredData' },
  'text/markdown': { name: 'Markdown', code: 'structuredData' },
  'application/pdf': { name: 'PDF', code: 'document' },
  'text/x-php': { name: 'PHP', code: 'code' },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': { name: 'Presentazione PowerPoint', code: 'presentation' },
  'text/x-python': { name: 'Python', code: 'code' },
  'text/x-script.python': { name: 'Python Script', code: 'code' },
  'text/x-ruby': { name: 'Ruby', code: 'code' },
  'text/x-tex': { name: 'TeX', code: 'document' },
  'text/plain': { name: 'Testo Semplice', code: 'document' },
  'text/css': { name: 'CSS', code: 'code' },
  'text/javascript': { name: 'JavaScript', code: 'code' },
  'application/x-sh': { name: 'Shell Script', code: 'code' },
  'application/typescript': { name: 'TypeScript', code: 'code' },
  'application/csv': { name: 'CSV', code: 'structuredData' },
  'image/jpeg': { name: 'Immagine', code: 'image' },
  'image/gif': { name: 'Immagine', code: 'image' },
  'image/png': { name: 'Immagine', code: 'image' },
  'text/csv': { name: 'CSV', code: 'structuredData' },
  'application/octet-stream': { name: 'Immagine', code: 'structuredData' },
  'application/x-tar': { name: 'Archivio TAR', code: 'archive' },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { name: 'Foglio di calcolo Excel', code: 'structuredData' },
  'application/xml': { name: 'XML', code: 'structuredData' },
  'text/xml': { name: 'XML', code: 'structuredData' },
  'application/zip': { name: 'Archivio Zip', code: 'archive' },
  'audio/mpeg': { name: 'Audio', code: 'audio' },
  'video/mp4': { name: 'Audio', code: 'audio' },
  'video/mpeg': { name: 'Audio', code: 'audio' },
  'audio/mp4': { name: 'Audio', code: 'audio' },
  'audio/wav': { name: 'Audio', code: 'audio' },
  'video/webm': { name: 'Audio', code: 'audio' }
};

const mappingIcon = (mimeType: string): JSX.Element => {
  let icon = (<DataObjectIcon fontSize='large' sx={{ color: green[500] }}/>);
  if (!mimeToReadableName[mimeType]) { return icon; }
  switch (mimeToReadableName[mimeType].code) {
    case 'document':
      icon = (<ArticleIcon fontSize='large' sx={{ color: blue[500] }}/>);
      break;
    case 'structuredData':
      icon = (<DataObjectIcon fontSize='large' sx={{ color: green[500] }}/>);
      break;
    case 'code':
      icon = (<CodeIcon fontSize='large' sx={{ color: orange[500] }}/>);
      break;
    case 'archive':
      icon = (<FolderZipIcon fontSize='large'/>);
      break;
    case 'image':
      icon = (<ImageIcon fontSize='large' sx={{ color: red[500] }}/>);
      break;
    case 'presentation':
      icon = (<SlideshowIcon fontSize='large' sx={{ color: teal[500] }}/>);
      break;
    case 'audio':
      icon = (<MusicVideoIcon fontSize='large' sx={{ color: blue[500] }}/>);
      break;
    default:
      icon = (<DataObjectIcon fontSize='large' sx={{ color: green[500] }}/>);
  }
  return icon;
};

const mappingNameType = (mimeType: string): any => {
  if (mimeType in mimeToReadableName) {
    return mimeToReadableName[mimeType].name;
  } else {
    return 'Tipo Sconosciuto';
  }
};

const FileCard = ({
  name,
  type,
  onCloseClick
}: { name: string, type: string, onCloseClick: React.MouseEventHandler<HTMLButtonElement> }): JSX.Element => {
  return (
    <Card variant='outlined' sx= {{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
          {mappingIcon(type)}
        </Box>
        <CardContent sx={{ height: 80, width: 'auto' }}>
          <Typography component="div" variant="body1">
            {name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {mappingNameType(type)}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          <IconButton aria-label="delete" size="medium" onClick={onCloseClick}>
            <CloseIcon></CloseIcon>
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

export default FileCard;
