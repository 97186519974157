import { Badge, Button, Typography } from '@mui/material';
import React, { type RefObject, useState } from 'react';

/** Mutation Observer per lo span di ReleaseApp
 *
 * @param ref Riferimento allo span dedicato di releseapp
 * @param callback funzione che aggiorna il flag sulla base dei cookie
 * @param options si scatena al cambio di attibuti
 */
const useMutationObserver = (
  ref: RefObject<HTMLSpanElement>,
  callback: MutationCallback,
  options = {
    attributes: true
  }
): void => {
  React.useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => { observer.disconnect(); };
    }
  }, [callback, options]);
};

export function NewsBadge ({ nodeRef, state }: { nodeRef: React.RefObject<Node>, state: boolean }): JSX.Element {
  const [badgeContent, setBadgeContent] = useState(state);

  useMutationObserver(nodeRef as RefObject<HTMLSpanElement>, () => {
    const cookieValue = localStorage.getItem('releasesapp_latest_read');
    setBadgeContent(cookieValue === 'true');
  }, {
    attributes: true
  });

  const handleGetUpdates = (): void => {
    // @ts-expect-error releaseapp is script for changelog
    releasesapp.updates();
  };

  return (<Badge
      variant='standard'
      overlap='rectangular'
      badgeContent=''
      invisible={badgeContent}
      color='primary'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Button
          onClick={handleGetUpdates}
          sx={{
            cursor: 'pointer',
            backgroundColor: '#212121',
            paddingRight: '24px',
            paddingLeft: '24px',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#212121',
              boxShadow: 'none'
            }
          }}
        >
          <Typography
            color={'#fff'}
            fontSize={16}
            variant='button'
            sx={{ textTransform: 'capitalize' }}
          >Novità</Typography>
        </Button>
    </Badge>
  );
}
