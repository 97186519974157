import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Chat } from './Chat';
import { Drawer, Box, Button, CssBaseline, IconButton, AppBar, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import Link from '@mui/material/Link';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { NewsBadge } from 'components/NewsBadge';

function OptionPanel ({ temperature, handleTemperature, resetSession }: any): JSX.Element {
  const handleChange = (e: Event, newValue: number | number[]): void => {
    handleTemperature(newValue as number);
  };

  const handleResetChat = (): void => {
    resetSession((Math.random() + 1).toString(36).substring(7));
  };

  return (
    <Stack
      justifyContent="space-between"
      style={{
        backgroundColor: '#212121',
        paddingRight: '32px',
        paddingLeft: '32px',
        position: 'relative',
        height: '100%',
        width: '240px'
      }}>
      <Stack>
        <TitleTypography>Impostazioni</TitleTypography>
        <Stack
          direction="row"
          style={{ paddingTop: 32 }}
          justifyContent="space-between"
        >
          <TitleSettingTypography>Temperatura</TitleSettingTypography>
          <Tooltip title="Più la temperatura è alta, più gli output del modello saranno sorprendenti e fantasiosi, ma anche meno precisi e meno attendibili. Al contrario, se la temperatura è bassa, gli output del modello saranno più conservativi e attendibili, ma anche meno originali e creativi.">
            <InfoOutlinedIcon htmlColor="#fff" />
          </Tooltip>
        </Stack>
        <Slider defaultValue={65} value={temperature} onChange={handleChange} />
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <LittleSettingTypography>Conservativa</LittleSettingTypography>
          <LittleSettingTypography>Creativa</LittleSettingTypography>
        </Stack>
      </Stack>
      <Stack>
        <Button
          onClick={handleResetChat}
          sx={{ textTransform: 'capitalize', marginBottom: '16px' }}
          variant="outlined"
          fullWidth
          startIcon={<RestartAltIcon />}
        >
          Reset chat
        </Button>
        <Box mb={2}>
          <Link
            href="mailto:ai@digital360.it"
            target="_blank"
            underline="none"
          >
            Hai bisogno di supporto?
          </Link>
        </Box>
        <div style={{ height: 1, backgroundColor: '#424242', marginLeft: -32, marginRight: -32 }}></div>
        <img
          style={{ paddingTop: 16, paddingBottom: 16 }}
          alt="logo" src="https://cdnd360.it/networkdigital360/nd360.png" />
      </Stack>
    </Stack>
  );
}

function LittleSettingTypography ({ children }: any): JSX.Element {
  return (
    <Typography
      color={'#fff'}
      fontSize={10}
    >
      { children }
    </Typography>
  );
}

function TitleSettingTypography ({ children }: any): JSX.Element {
  return (
    <Typography
      color={'#fff'}
      fontSize={16}
      fontWeight={700}
    >
      {children}
    </Typography>
  );
}

function TitleTypography ({ children }: any): JSX.Element {
  return (
    <Typography
      sx={{ pt: '32px' }}
      color={'#fff'}
      fontSize={16}
    >
      {children}
    </Typography>
  );
}

function GradientLine (): JSX.Element {
  return (
    <div
      style={{
        bottom: 0,
        width: '100%',
        height: 3,
        background: 'transparent linear-gradient(270deg, #20A537 0%, #0589C9 100%) 0% 0% no-repeat padding-box'
      }}
    />
  );
}

// esegue nuovamente lo script di ReleaseApp
const useScriptLoader = (src: string): void => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
};

function MainPage ({ signOut, user }: any): JSX.Element {
  const [mobileOpen, setMobileOpen] = useState(false);

  const fullName = `${user.attributes?.given_name ?? ''} ${user.attributes?.family_name ?? ''}`;
  const email = user.attributes?.email;

  const [temperature, setTemperature] = useState(80);
  const [actualSession, handleSession] = useState((Math.random() + 1).toString(36).substring(7));

  const theme: any = useTheme();
  const isDesktop = useMediaQuery(theme?.breakpoints?.up('sm'));

  const nodeRef = useRef<HTMLSpanElement>(null);
  useScriptLoader('https://widget.releasesapp.com/js/v1.js');
  const cookieValue = localStorage.getItem('releasesapp_latest_read');
  const state = cookieValue === 'true';

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{
      display: { sm: 'flex' },
      height: '100%',
      width: '100%',
      pt: `${theme.constants.appbarHeight}px`
    }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon htmlColor={theme.palette.primary.main} />
          </IconButton>
          <Box display="flex" width={{ xs: 133, sm: 198 }} mr={2} >
            <Logo />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
            <Stack direction='row'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <NewsBadge nodeRef={nodeRef} state={state}/>
              </Box>
              <Stack style={{ marginLeft: '24px', marginTop: 10, marginBottom: 9 }}>
                <Typography
                  sx={{ fontSize: { xs: 14, md: 16 } }}
                  color={'#212121'}>
                  {fullName}
                </Typography>
                <Typography
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                  fontSize={12}
                  color={'#9e9e9e'}>
                  {email}
                </Typography>
              </Stack>
              <IconButton onClick={() => { signOut(); }}>
                <LogoutIcon htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Stack>
          </Box>
        </Toolbar>
        <GradientLine />
      </AppBar>
      <Box component="nav" top={theme.constants.appbarHeight}>
        <Drawer
          onClose={handleDrawerToggle}
          variant={ isDesktop ? 'permanent' : 'temporary' }
          open={mobileOpen}
          sx={{
            width: theme.constants.drawerWidth,
            height: `calc(100vh - ${theme.constants.appbarHeight}px)`,
            top: theme.constants.appbarHeight
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            BackdropProps: {
              style: {
                top: theme.constants.appbarHeight
              }
            },
            disableEnforceFocus: true
          }}
          PaperProps = {{
            style: {
              top: theme.constants.appbarHeight,
              height: `calc(100% - ${theme.constants.appbarHeight}px)`
            }
          }}
          >
          <OptionPanel
            temperature={temperature}
            handleTemperature={setTemperature}
            resetSession={handleSession} />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        <Chat temperature={temperature} key={actualSession} />
      </Box>
      <span ref={nodeRef} id="releasesapp__unread__badge" style={{ display: 'none' }}></span>
    </Box>
  );
}

export { MainPage };
